import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Wizard } from '@shared/ui/misc/Wizard';
import { useAppSelector } from '@app/store/Hooks';
import Initial from './steps/Initial';
import Credentials from './steps/Credentials';
import EmailConfirmationStep from '../../components/EmailConfirmationStep';
const SignIn = () => {
    const { authStep } = useAppSelector(state => state.auth);
    return (_jsxs(Wizard, { initialStep: typeof authStep === 'number' ? authStep : undefined, children: [_jsx(Initial, {}), _jsx(Credentials, {}), _jsx(EmailConfirmationStep, {})] }));
};
export default SignIn;
